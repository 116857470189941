import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal, Toast, ToastHeader, ToastBody } from "reactstrap";

import axios from "axios";

import logo from "../../../assets/images/logo-sm.png";

const BasicTable = ({ labBranchfilterData, fetchData, userToken }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [labBranchId, setLabBranchId] = useState("");
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone No</span>,
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">City</span>,
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Address</span>,
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="edit"
              onClick={() => {
                setLabBranchId(cell._id);
                setIsEditModalOpen(!isEditModalOpen);
              }}
            >
              <button
                className="btn btn-sm btn-success edit-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#showModal"
              >
                Edit
              </button>
            </div>
            <div
              className="remove"
              onClick={() => {
                setLabBranchId(cell._id);
                setIsDeleteModalOpen(!isDeleteModalOpen);
              }}
            >
              <button
                className="btn btn-sm btn-danger remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#deleteRecordModal"
              >
                Remove
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const data2 = labBranchfilterData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      name: data.name,
      email: data.email,
      phone: data.phoneNumber,
      city: data.city,
      address: data.address,
      status: data.online,
    };
    return obj;
  });

  return (
    <>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      {/* // All table data start */}
      <DataTable columns={columns} data={data2} pagination />

      {/* edit lab branch */}
      {isEditModalOpen && (
        <EditLabBranch
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          apiRoute={apiRoute}
          labBranchId={labBranchId}
          bearerToken={userToken}
          labBranchfilterData={labBranchfilterData}
          fetchData={fetchData}
          settoast1={settoast1}
          setToastMessage={setToastMessage}
        />
      )}

      {/* delete lab branch */}
      {isDeleteModalOpen && (
        <DeleteLabBranch
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          apiRoute={apiRoute}
          labBranchId={labBranchId}
          bearerToken={userToken}
          fetchData={fetchData}
          settoast1={settoast1}
          setToastMessage={setToastMessage}
        />
      )}
    </>
  );
};

const EditLabBranch = ({
  isEditModalOpen,
  setIsEditModalOpen,
  apiRoute,
  labBranchId,
  bearerToken,
  labBranchfilterData,
  fetchData,
  settoast1,
  setToastMessage,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [apiLoading, setApiLoading] = useState(false);

  useEffect(() => {
    const [singleData] = labBranchfilterData.filter(
      (data) => data._id === labBranchId
    );
    setName(singleData.name);
    setEmail(singleData.email);
    setPhoneNumber(singleData.phoneNumber);
    setCity(singleData.city);
    setAddress(singleData.address);
  }, [labBranchfilterData, labBranchId]);

  const handleUpdateBranch = async () => {
    try {
      setApiLoading(true);
      const data = await axios.put(
        `${apiRoute}/api/laboratory/auth/updateBranch`,
        {
          name,
          email,
          phoneNumber,
          password,
          city,
          address,
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      if (data.status === 200) {
        fetchData();
        setIsEditModalOpen(false);
        settoast1(true);
        setToastMessage(data.message);
      }
    } catch (err) {
      const errSplit = err.split(" ");
      const statusCode = errSplit[errSplit.length - 1];

      if (statusCode === "401") {
        alert("Email not Exist, Please SignUp First");
      }
    }
    setApiLoading(false);
  };

  return (
    <Modal
      isOpen={isEditModalOpen}
      toggle={() => {
        setIsEditModalOpen(!isEditModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Update Data</h5>
        <button
          type="button"
          onClick={() => {
            setIsEditModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-12 col-form-label"
          >
            Name :
          </label>
          <div className="col-md-12">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Full Name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <label
            htmlFor="example-text-input"
            className="col-md-12 col-form-label"
          >
            Email :
          </label>
          <div className="col-md-12">
            <input
              type="text"
              className="form-control bg-light"
              style={{ cursor: "not-allowed" }}
              placeholder="Email"
              required
              value={email}
              disabled
            />
          </div>
          <label
            htmlFor="example-text-input"
            className="col-md-12 col-form-label"
          >
            Password :
          </label>
          <div className="col-md-12">
            <input
              type="text"
              className="form-control"
              placeholder="Enter New Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <label
            htmlFor="example-text-input"
            className="col-md-12 col-form-label"
          >
            Phone No :
          </label>
          <div className="col-md-12">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Phone Number"
              required
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <label
            htmlFor="example-text-input"
            className="col-md-12 col-form-label"
          >
            City :
          </label>
          <div className="col-md-12">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your City"
              required
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <label
            htmlFor="example-text-input"
            className="col-md-12 col-form-label"
          >
            Address :
          </label>
          <div className="col-md-12">
            <textarea
              required
              className="form-control"
              placeholder="Enter Your Address *"
              rows="3"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleUpdateBranch}
          disabled={apiLoading}
        >
          UPDATE
        </button>
      </div>
    </Modal>
  );
};

const DeleteLabBranch = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  apiRoute,
  labBranchId,
  bearerToken,
  fetchData,
  settoast1,
  setToastMessage,
}) => {
  const [apiLoading, setApiLoading] = useState(false);

  const handleDeleteBranch = async () => {
    try {
      setApiLoading(true);
      const data = await axios.delete(
        `${apiRoute}/api/laboratory/auth/deleteBranch/${labBranchId}`,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      if (data.status === 200) {
        fetchData();
        setIsDeleteModalOpen(false);
        settoast1(true);
        setToastMessage(data.message);
      }
    } catch (err) {
      const errSplit = err.split(" ");
      const statusCode = errSplit[errSplit.length - 1];

      if (statusCode === "400") {
        alert("Laboratory Branch Not Found");
      }
    }
    setApiLoading(false);
  };

  return (
    <Modal
      isOpen={isDeleteModalOpen}
      toggle={() => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          Are You Sure to Delete This Branch?
        </h5>
        <button
          type="button"
          onClick={() => {
            setIsDeleteModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          onClick={() => {
            setIsDeleteModalOpen(false);
          }}
        >
          No
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleDeleteBranch}
          disabled={apiLoading}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};

export { BasicTable };
