import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import axios from "axios";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import logo from "../../../assets/images/logo-sm.png";

import { BasicTable } from "./datatableCom";

const TestManagement = (props) => {
  document.title = "Test Management | Laboratory Panel";
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  const [testApiData, setTestApiData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  // const [apiLoading, setApiLoading] = useState(false);
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  let user = sessionStorage.getItem("authUser");
  user = JSON.parse(user);
  const userToken = user.token;

  // get users data
  const fetchTestData = async () => {
    try {
      const { data } = await axios.get(
        `${apiRoute}/api/laboratory/auth/getLabTests`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setTestApiData(data);
      setFilterData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    fetchTestData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  const filterList = (e) => {
    const value = e.target.value.toLowerCase();
    const newData = testApiData.filter((item) =>
      item.testTitle.toLowerCase().includes(value)
    );
    setFilterData(newData);
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Laboratory Panel"
            breadcrumbItem="Test Management"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm gap-2">
                        <label className="mt-2">Search by Test Name: </label>
                        <div className="position-relative bg-light">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            onChange={(e) => filterList(e)}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm-auto">
                      <div className="d-flex gap-1">
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => setIsAddModalOpen(!isAddModalOpen)}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Lab Test
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <BasicTable testApiData={filterData} />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* // add lab test */}
          {isAddModalOpen && (
            <AddNewLabTest
              isAddModalOpen={isAddModalOpen}
              setIsAddModalOpen={setIsAddModalOpen}
              apiRoute={apiRoute}
              testApiData={testApiData}
              userToken={userToken}
              settoast1={settoast1}
              setToastMessage={setToastMessage}
              fetchTestData={fetchTestData}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const AddNewLabTest = ({
  isAddModalOpen,
  setIsAddModalOpen,
  apiRoute,
  testApiData,
  userToken,
  settoast1,
  setToastMessage,
  fetchTestData,
}) => {
  const [testId, setTestId] = useState("");
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");

  const [testList, setTestList] = useState([]);

  const fetchLabTests = async () => {
    try {
      const { data } = await axios.get(`${apiRoute}/api/admin/allLabTests`);
      setTestList(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    fetchLabTests();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddTest = async () => {
    try {
      const postData = {
        testId,
        price,
        duration,
      };
      const data = await axios.post(
        `${apiRoute}/api/laboratory/auth/addLabTest`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (data.status === 201) {
        settoast1(true);
        setToastMessage(data.message);
        setIsAddModalOpen(false);
        fetchTestData();
      }
    } catch (err) {
      if (err) {
        setToastMessage("Something went wrong! Try again later!");
      }
    }
  };

  return (
    <Modal
      isOpen={isAddModalOpen}
      toggle={() => {
        setIsAddModalOpen(!isAddModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Add New Test</h5>
        <button
          type="button"
          onClick={() => {
            setIsAddModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label htmlFor="recipient-name" className="col-form-label">
            Currently Available Tests :
          </label>
          <div className="col-md-12">
            <select
              className="form-control"
              onChange={(e) => setTestId(e.target.value)}
            >
              <option hidden>Select Test</option>
              {testList.length > 0 &&
                testList.map((test) => {
                  const isMatch = testApiData.some(
                    (data) => data.testId === test._id
                  );
                  return (
                    <option
                      key={test._id}
                      value={test._id}
                      style={{
                        fontSize: "16px",
                        height: "auto",
                        color: isMatch ? "red" : "black",
                      }}
                      disabled={isMatch}
                    >
                      {test.title}
                    </option>
                  );
                })}
            </select>
          </div>
          <label htmlFor="recipient-name" className="col-form-label">
            Price in $ :
          </label>
          <div className="col-md-12">
            <input
              className="form-control"
              type="number"
              placeholder="Enter Price"
              min={6}
              required
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <label htmlFor="recipient-name" className="col-form-label">
            Duration :
          </label>
          <div className="col-md-12">
            <input
              className="form-control"
              type="text"
              placeholder="Enter Test Duration"
              min={6}
              required
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddTest}
        >
          Add
        </button>
      </div>
    </Modal>
  );
};

export default TestManagement;
