import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import axios from "axios";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { BasicTable } from "./datatableCom";

const ProductManagement = (props) => {
  document.title = "Branches List";

  const [labTestApiData, setLabTestApiData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  let user = sessionStorage.getItem("authUser");
  user = JSON.parse(user);
  const userToken = user.token;

  // get lab test data
  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${apiRoute}/api/laboratory/auth/allBranches`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      setLabTestApiData(data);
      setFilterData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  // get api data
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterList = (e) => {
    const value = e.target.value.toLowerCase();
    const newData = labTestApiData.filter((item) =>
      item.name.toLowerCase().includes(value)
    );
    setFilterData(newData);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Laboratory Organization"
            breadcrumbItem="Branches"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex gap-2">
                    <label className="mt-2">Search by Name: </label>
                    <div className="position-relative bg-light">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => filterList(e)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <BasicTable
                    labBranchfilterData={filterData}
                    fetchData={fetchData}
                    userToken={userToken}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ProductManagement;
