import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import axios from "axios";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { BasicTable } from "./datatableCom";

const OrderManagement = (props) => {
  document.title = "Order Management | Laboratory Panel";
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  const [orderData, setOrderData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [toast1, settoast1] = useState(false);

  let user = sessionStorage.getItem("authUser");
  user = JSON.parse(user);
  const userToken = user.token;

  // get users data
  const fetchOrderData = async () => {
    try {
      const { data } = await axios.get(
        `${apiRoute}/api/laboratory/auth/labTestOrderData`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setOrderData(data);
      setFilterData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    fetchOrderData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  const filterList = (e) => {
    const value = e.target.value.toLowerCase();
    const newData = orderData.filter((item) =>
      item.patientInfo.fullName.toLowerCase().includes(value)
    );
    setFilterData(newData);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Laboratory Panel"
            breadcrumbItem="Order Management"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm gap-2">
                        <label className="mt-2">Search by Test Name: </label>
                        <div className="position-relative bg-light">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            onChange={(e) => filterList(e)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <BasicTable
                      orderData={filterData}
                      fetchOrderData={fetchOrderData}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default OrderManagement;
